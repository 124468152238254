/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* ngx-toast */
@import 'ngx-toastr/toastr';

/* ngx-emoji-mart */
@import "@ctrl/ngx-emoji-mart/picker";